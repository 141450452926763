import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

const NotFoundPage = () => {
  return (
    <Layout>
      <title>Not found</title>
      <h1>Sidan hittades inte <span role="img" aria-label="Pensive emoji">
        😔
      </span></h1>
        <p class="centered"><Link to="/">Gå tillbaka till startsidan</Link></p>
    </Layout>
  )
}

export default NotFoundPage
